<template>
  <v-card
    max-width="unzise"
    class="mx-auto"
  >
    <v-list>
      
      <!-- CONTRATOS -->
      <v-list-group
        :value="false"
        prepend-icon="mdi-file-sign"
        active-class="grey--text"
        
      >
        <template v-slot:activator>
          <v-list-item-title>Contratos</v-list-item-title>
        </template>

        <v-list-group
          :value="false"
          no-action
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Agregar Nuevo Contrato</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
           
            v-for="(item, i) in subItemsContrato"
            :key="i"
            link
             @click="menuActionClick(item.action)"
          >
            <v-list-item-title >{{item.titulo}}</v-list-item-title>

            <v-list-item-icon>
              <v-icon >{{item.icono}}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-list-group
          no-action
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Anexo Contrato</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="([title, icon], i) in anexoContratos"
            :key="i"
            link
          >
            <v-list-item-title v-text="title"></v-list-item-title>

            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

                <v-list-group
          no-action
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Gestionar Contrato</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(item, i) in gestionarContratos"
            :key="i"
            link
            @click="menuActionClick(item.action)"
          >
            <v-list-item-title >{{item.titulo}}</v-list-item-title>

            <v-list-item-icon>
              <v-icon >{{item.icono}}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

 
   <v-list flat>

      <v-list-item-group
        v-model="selectedItem"
      >
        <v-list-item>
          <v-list-item-icon>
   
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title @click="simularInforme()">Simular Informe</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>


      </v-list-group> 

      <!-- INFORMES -->
      <v-list-group
        :value="false"
        prepend-icon="mdi-chart-timeline"
        active-class="grey--text"
      >
        <template v-slot:activator>
          <v-list-item-title>Informes</v-list-item-title>
        </template>

        <v-list-group
          :value="true"
          no-action
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Contrato</v-list-item-title>
            </v-list-item-content>
          </template>

        </v-list-group>

        <v-list-group
          no-action
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Cartera</v-list-item-title>
            </v-list-item-content>
          </template>


        </v-list-group>
      </v-list-group>

      <!-- COMISIONES -->
      <v-list-group
        :value="false"
        prepend-icon="mdi-hand-coin"
        active-class="grey--text"
      >
        <template v-slot:activator>
          <v-list-item-title>Comisiones</v-list-item-title>
        </template>

   


      </v-list-group>


        <!--EMPRESAS  -->
      <v-list-group
        :value="false"
        prepend-icon="mdi-domain"
        active-class="grey--text"
      >
        <template v-slot:activator>
          <v-list-item-title>Empresas</v-list-item-title>
        </template>

      </v-list-group>

    </v-list>
  </v-card>
</template>

<script>


  export default {
    name: 'Home',
    data: () => ({
      contrato: false,
   subItemsContrato: [
        {
          titulo: 'Familiar', icono: 'mdi-human-male-female-child', action: 'Familiar'
        },
        {
          titulo: 'Colectivo', icono: 'mdi-crowd', action: 'Colectivo'
        },
        {
          titulo: 'Area Protegida', icono: 'mdi-alert', action: 'AreaProtegida'
        },
      ],


      anexoContratos: [
        ['Familiares Anexo', 'mdi-human-male-female-child'],
        ['Colectivo Anexo', 'mdi-crowd'],
      ],

      gestionarContratos:[
        { titulo: 'Consultar Contratos', action: 'consultar-contratos'},
      ],

      itemsContrato: [
       { 
         titulo: 'Agregar Nuevo Contrato',
         icono: 'mdi-file-plus'
       },
       {
         titulo: 'Anexo Contrato',
         icono: 'mdi-finance'
       },
       {
         titulo: 'Gestionar Contrato',
         icono: 'mdi-file-cog',
       },
         {
         titulo: 'Simular Tarifa',
         icono: 'mdi-finance'
       },
      ],
       selectedItem: 1,
      items: [
        { text: 'Simular Tarifa', icon: 'mdi-clock', action: 'simular-tarifa' },
     
      ],
    }),

    created(){
      if (this.$store.state.roles_afiliacion_asesores.includes("cliente")) {
        this.$router.push("/simular-tarifa");
      }
    },
    components: {

    },
    methods: {
      abrir(item){
          switch (item) {
            case 'Contrato':
                console.log(item);
                this.contrato = !this.contrato;
              break;
            case 'Informes':
                console.log(item);
              break;
            case 'Comisiones':
                console.log(item);
              break;
            case 'Empresas':
                console.log(item);
                alert('empre')
              break;
                case 'gestionar-contrato':
                console.log(item);
              break;
            default:
              break;
          }

      },
    menuActionClick(action) {
      switch (action) {
        case 'AreaProtegida':
          alert('Area')
        break;
              case 'Familiar':
         
              this.$router.push({ path: 'afiliacion/1' });
        break;
              case 'Colectivo':
         alert('Colectivo')
        break;
        case 'consultar-contratos':
         this.$router.push({ path: 'consultar-contratos' });
        
        break;
        case 'estado-contratos':
         this.$router.push({ path: 'estado-contratos' })
         console.log('Estado');
        break;
      
        default:
          break;
      }
    },
    simularInforme(){
      this.$router.push({ path: 'simular-tarifa' })
    }
    }
  }
</script>
