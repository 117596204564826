import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../modules/login/Login.vue')
  },
  {
    path: '/afiliacion/:id_plan',
    name: 'afiliacion',
    component: () => import('../modules/afiliacion/Afiliacion.vue')
  },
  {
    path: '/respuesta-pago',
    name: 'respuesta-pago',
    component: () => import('../modules/afiliacion/RespuestaPagoAfiliacion.vue')
  },
  {
    path: '/simular-tarifa',
    name: 'SimularTarifa',
    component: () => import('../modules/afiliacion/SimularTarifa.vue')
  },
  {
    path: '/contratos-guardados',
    name: 'contratos-guardados',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContratosGuardados.vue')
  },
  {
    path: '/consultar-contratos',
    name: 'consultar-contratos',
    component: () => import(/* webpackChunkName: "about" */ '../modules/contratos/ConsultarContratos.vue')
  },
  {
    path: '/estado-contratos',
    name: 'estado-contratos',
    component: () => import(/* webpackChunkName: "about" */ '../views/EstadoContratos.vue')
  },
  {
    path: '/home2',
    name: 'home2',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home2.vue')
  },
  {
    path: '/contratos',
    name: 'contratos',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contratos.vue')
  },
  {
    path: '/agregar-contrato',
    name: 'agregar-contratosApp',
    component: () => import(/* webpackChunkName: "about" */ '../views/AgregarContrato.vue')
  },
  {
    path: "**",
    name: "not-found",
    component: () => import("../modules/general/NotFoundView.vue"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
