import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    loading: false,
    token_afiliacion_asesores: null,
    user_afiliacion_asesores: {},
    roles_afiliacion_asesores: ["cliente"],
  },
  mutations: {
    setToken(state, token) {
      console.log(token);
      state.token_afiliacion_asesores = token;
    },
    setUser(state, user) {
      console.log(user);
      state.user_afiliacion_asesores = user;
    },
    setRoles(state, roles) {
      console.log(roles);
      state.roles_afiliacion_asesores = roles;
    },
    cerrarSesion(state){
      state.token_afiliacion_asesores = null;
      state.user_afiliacion_asesores = {};
      state.roles_afiliacion_asesores = ["cliente"];
      location.reload();
    }
  },
  actions: {},
  modules: {},
});
