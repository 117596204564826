<template>
  <v-app style="background-color: #F7F7F7;">
    <v-app-bar app color="grey lighten-5" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-menu offset-y v-if="this.$store.state.token_afiliacion_asesores != null">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary"  v-bind="attrs"
          v-on="on" text plain>
            Usuario
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item style="cursor:pointer" @click="cerrarSesion">
            <v-list-item-title>Cerrar Sesion</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <!-- EN EL CASO QUE EL ICONO DE CARGA ESTE ACTIVO NO SE MOSTRARA LA VISTA -->

      <!-- VISTAS -->
      <v-container fluid v-show="!$store.state.loading">
        <router-view />
      </v-container>

      <!-- ICONO DE CARGA -->
      <v-container fluid class="mt-n15" v-if="$store.state.loading">
        <pageSpinner></pageSpinner>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import pageSpinner from "./modules/general/components/page-spinner.vue";
export default {
  name: "App",
  components: {
    pageSpinner,
  },
  data: () => ({}),
  beforeCreate() {
    if (this.$store.state.roles_afiliacion_asesores == undefined) {
      this.$store.commit("setRoles", ["cliente"]);
    }
  },
  methods:{
    cerrarSesion(){
      this.$store.commit("cerrarSesion");
    }
  }
};
</script>
<style>
/* Estilo para los asteriscos de los inputs se vean obligatorios */
.campo_obligatorio {
  color: red;
  margin-left: 2px;
}
.swal2-container {
  font-family: Arial, Helvetica, sans-serif;
}
</style>
